import * as React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { UseKeycloakService } from '../../KeycloakProvider';

// todo : mettre dans flexy
// todo : tester

export type KeycloakRouteProps = {
    children: React.ReactNode;
    roles?: string | string[] | undefined;
    fallBackRoleLocation?: string;
    fallBackAuthLocation?: string;
};

export const KeycloakRoute = function ({
    children,
    roles = undefined,
    fallBackRoleLocation = '/errors/403',
    fallBackAuthLocation = '/errors/401',
}: KeycloakRouteProps) {
    const location = useLocation();
    const KeycloakService = UseKeycloakService();

    if (!KeycloakService.isAutenticated()) {
        console.error(`user not autenticated ${roles}`);
        return (
            <Navigate
                to={fallBackAuthLocation}
                state={{ from: location, authorisationError: true }}
                replace
            />
        );
    }
    if (!KeycloakService.checkRole(roles)) {
        console.error(`missing role ${roles}`);
        return (
            <Navigate
                to={fallBackRoleLocation}
                state={{ from: location, authorisationError: true }}
                replace
            />
        );
    }

    return children;
};

export default KeycloakRoute;
